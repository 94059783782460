@import '@/_styles/import';
.root {
  width: 93.4%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 30px 0;

  :global(.loading-skeleton) {
    --base-color: #{$gray-110};

    display: flex;
    height: inherit;
  }
}

.top {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.status {
  width: 40.85%;
  height: 24px;
}

.dropdowns {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  width: 45%;
}

.label {
  width: 6.25%;
  height: 20px;
}

.selector {
  width: 41.5%;
  height: 40px;
}

.marquee {
  display: block;
  width: 100%;
  height: 61px;
  margin: 20px 0 0;
}

.main {
  &:global(.PackageCardChooseVoyage) {
    margin: 50px 0 0;
  }
}
